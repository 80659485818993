/* eslint-disable @wordpress/dependency-group */
// import $ from 'jquery';

// import './inc/modals';
// import './inc/responsive-menus';
// import './inc/sliders';
// import SQHThemeAccordions from './inc/accordions';
// import SQHThemeTrustYou from './inc/trustyou';
// import SQHThemeHotelsFilters from './inc/hotels-filters';
// import SQHThemeHotels from './inc/hotels';
// import SQHThemeUtils from './inc/utils';
// import 'slick-carousel-latest';
// import 'jquery-blockui';
import Cookies from 'js-cookie';
import 'slick-carousel-latest';
import './inc/async-content';

(function($){

    const BrodwayElectricInc = {

        init: function() {
            
            this.initSliders();
            this.initMenus();
            
            setTimeout(function(){
                BrodwayElectricInc.sizeFooterProjectsOverlays();
            }, 500);

            if( jQuery('ul.lightning').length ) {
                jQuery('ul.lightning').each(function(idx, list){
                    jQuery(this).find('li').contents().wrap('<span style="max-width: 90%;"></span>');
                });
            }

            if( jQuery('#content ul').length ) {
                jQuery('#content ul').each(function(idx, list){
                    jQuery(this).find('li').contents().wrap('<span style="max-width: 90%;"></span>');
                });
            }

            if( jQuery('.bei-modal').length ) {

                let dismiss = theme.preloaderDismiss;
                let dismissCookie = Cookies.get('bp-dismissed');

                if( ( ( dismiss == 'weekly' || dismiss == 'monthly' ) && ( ! dismissCookie || dismissCookie == undefined ) ) || dismiss == 'always' ) {
                
                    jQuery('.bei-modal').each(function(idx, modalWindow){
                        let auto = jQuery(this).attr('data-auto');
                        let modal = jQuery(this);
                        if( auto && auto == 'true' ) {
                            modal.show().css('display', 'flex');
                            setTimeout(function(){
                                modal.find('.bei-modal-content').addClass('active');
                                setTimeout(function(){
                                    BrodwayElectricInc.dismissPreloader();
                                }, 5000);
                            }, 500);
                        }
                    });

                }

            }

        },
        dismissPreloader: function() {

            jQuery('.bei-modal .bei-modal-content').css('top', '-1000px');

            setTimeout(function(){
                switch( theme.preloaderDismiss ) {
                    case 'weekly':
                        Cookies.set('bp-dismissed', '1', { expires: 7, path: '' });
                    break;
                    case 'monthly':
                        Cookies.set('bp-dismissed', '1', { expires: 30, path: '' });
                    break;
                }
                jQuery('.bei-modal').remove();
            }, 500);


        },
        sizeFooterProjectsOverlays: function() {
            
            if( jQuery('.bei-footer-latest-projects').length ) {
                jQuery('.bei-footer-latest-projects .bei-footer-project').each(function(idx, project){
                    let imgHeight = jQuery(this).find('img').height();
                    jQuery(this).find('.overlay').css({ height: imgHeight + 'px'} );
                });
            }

        },
        initSliders: function() {

            if( jQuery('.bei-slider').length ) {

                jQuery('.bei-slider').each(function(i, slider) {

                    let params = jQuery(this).attr('data-params');

                    params = params ? JSON.parse( params ) : [];

                    let settings = {
                        mobileFirst: true,
        		        // centerMode: true,
        		        // centerPadding: "30px",

                        slidesToShow: parseInt( params['slides_to_display'] ),
                        slidesToScroll: parseInt( params['slides_to_slide'] ),
                        arrows: parseInt( params['nextprev_arrows'] ) ? true : false,
                        dots: parseInt( params['dots_navigation'] ) ? true : false,
                        infinite: parseInt( params['is_infinite'] ) ? true : false,
                        speed: parseInt( params['transition_speed'] ) * 1000,
                        autoplay: parseInt( params['autoplay'] ) ? true : false,
                        autoplaySpeed: parseInt( params['autoplay_pause'] ) * 1000,
                        fade: params['transition_effect'] == 'fade' ? true : false
                        
                        // responsive: [
                        //     {
                        //       breakpoint: 1023,
                        //       settings: "unslick"
                        //     },
                        //     {
                        //       breakpoint: 559,
                        //       settings: {
                        //         slidesToShow: 2,
                        //       }
                        //     },
                        // ]
                    }
                    
                    jQuery(this).slick(settings);

                });

            }

            if( jQuery('.bei-project-slider').length ) {
                jQuery('.bei-project-slider').each(function(idx, slider){
                    jQuery(this).slick({
                        arrows: true,
                        dots: false,
                        infinite: true,
                        autoplay: true,
                        autoplaySpeed: 5000,
                        adaptiveHeight: true
                    });
                });
            }

            if( jQuery('.bei-project-discover-more-projects').length ) {
                jQuery('.bei-project-discover-more-projects').each(function(idx, slider){
                    jQuery(this).slick({
                        arrows: false,
                        dots: true,
                        infinite: true,
                        autoplay: true,
                        autoplaySpeed: 5000,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        responsive: [
                            {
                              breakpoint: 767,
                              settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                              }
                            },
                        ]
                    });
                });
            }

        },
        initMenus: function() {
            jQuery('.top-menu li.menu-item-has-children .sub-menu').before('<button class="sub-menu-toggle"></button>');
        },
        toggleSubMenu: function( menuItem ) {
            menuItem.toggleClass('active');
            menuItem.next('.sub-menu').toggleClass('active');
            menuItem.parent().toggleClass('active');
        },
        toggleSearchForm: function() {

            jQuery('.search-form .form').slideToggle();
            jQuery('.search-form .form input[type="text"]').focus();

        },
        filterProjects: function( cat ) {

            jQuery('.bei-projects-categories-filters .cat a').removeClass('active');

            cat.addClass('active');

            let catSlug = cat.attr('data-catslug');
            let elem = cat.parents('.bei-projects-container').find('.bei-slidable-element');

            if( catSlug != 'all' ) {

                elem.fadeOut();
                elem.each(function(idx, project){

                    let projCats = jQuery(this).attr('data-cats');
                    if( projCats.includes( catSlug ) ) {
                        jQuery(this).fadeIn();
                    }

                });

            } else {

                elem.fadeIn();

            }

        },
        toggleMobileMenu: function( trigger ) {
            let menu = trigger.attr('data-menu');
            if( menu ) {
                trigger.toggleClass('active');
                jQuery( menu ).toggleClass('active');
            }
        },
        toggleJob: function( trigger ) {
            let parent  = trigger.parents('li');
            let expand  = parent.find('.bei-job-expand');
            let content = parent.find('.bei-job-content');

            content.slideToggle();

            if( expand.hasClass('expand') ) {
                expand.removeClass('expand');
            } else {
                expand.addClass('expand');
            }
        }

    }

    jQuery(document).ready(function() {
        
        BrodwayElectricInc.init();

        jQuery('body').on('click', '.sub-menu-toggle', function(evt){
            BrodwayElectricInc.toggleSubMenu( jQuery(this) );
        });

        jQuery('.search-form .icon').on('click', function(evt) {
            BrodwayElectricInc.toggleSearchForm();
        });

        jQuery('.bei-projects-categories-filters a').on('click', function(evt){
            BrodwayElectricInc.filterProjects( jQuery(this) );
        });

        jQuery('#mobile-nav-toggle').on('click', function(evt){
            evt.preventDefault();
            BrodwayElectricInc.toggleMobileMenu( jQuery(this) );
        });

        jQuery('.bei-job-expand, .bei-job-title').on('click', function(evt){
            BrodwayElectricInc.toggleJob( jQuery(this) );
        });

        jQuery('.bei-modal-close, .bei-modal-bg').on('click', function(evt){
            BrodwayElectricInc.dismissPreloader();
        });

    });

    jQuery(document).on('click', 'a[href^="#"]', function(evt) {
        evt.preventDefault();
        
        let offset = jQuery( jQuery.attr( this, 'href' ) ).offset().top;

        if( theme.fixedHeader && theme.fixedHeader > 0 ) {
            let headerHeight = jQuery('header#header').height();
            offset -= headerHeight + 50;
        }

        jQuery('html, body').animate({
            scrollTop: offset
        }, 500);

    });

    jQuery(window).on( 'resize', function(){
        BrodwayElectricInc.sizeFooterProjectsOverlays();
    });

    jQuery(window).scroll(function() {    

        let scroll = jQuery(window).scrollTop();

        if( scroll >= 1 ) {
            //clearHeader, not clearheader - caps H
            jQuery('#header').addClass('opaque');
        } else {
            jQuery('#header').removeClass('opaque');
        }

    });

})(jQuery);
