(function($){

    const BalkanTrailsAsyncContent = {

        init: function() {

            if( jQuery('.async').length ) {

                jQuery('.async').each(function(){

                    getAsyncContent( jQuery(this) );

                    // let remove    = jQuery(this).attr('data-remove');
                    // let container = jQuery(this);

                    // container.html( '<div class="bt-preloader"></div>' );

                    // jQuery.ajax('/wp-json/bt/v1/gac', {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json'
                    //     },
                    //     beforeSend: function (xhr) {
                    //         xhr.setRequestHeader('X-WP-Nonce', btThemeFrontend.nonce)
                    //     },
                    //     data: JSON.stringify({
                    //         type: jQuery(this).data('type'),
                    //         postId: jQuery(this).data('id')
                    //     }),
                    //     dataType: 'json',
                    //     success: function( content ) {
                    //         if( content.result ) {
                    //             container.html( content.result );
                    //         } else {
                    //             jQuery( remove ).remove();
                    //         }
                    //     },
                    //     error: function( xhr, errorStatus, error ) {
                    //     }
                    // });

                });

            }

        }

    }

    jQuery('document').ready(function(){
        BalkanTrailsAsyncContent.init();
    });

})(jQuery);

export default function getAsyncContent( element ) {

    let remove    = element.attr('data-remove');
    let container = element;
    let preloader = element.attr('data-preloader');
    
    if( preloader != 'false' ) {
        container.html( '<div class="bt-preloader"></div>' );
    }

    jQuery.ajax('/wp-json/bt/v1/gac', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-WP-Nonce', btThemeFrontend.nonce)
        },
        data: JSON.stringify({
            type: element.data('type'),
            postId: element.data('id')
        }),
        dataType: 'json',
        success: function( content ) {
            if( content.result ) {
                container.html( content.result );
            } else {
                if( remove ) {
                    jQuery( remove ).remove();
                } else {
                    element.parent().remove();
                }
            }
        },
        error: function( xhr, errorStatus, error ) {
        }
    });

}